import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common/';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UiModule } from './ui/ui.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfoComponent } from './info/info.component';
import { ProfileComponent } from './profile/profile.component';
import { ChartsModule } from 'ng2-charts';
import { PCGlobalScoreComponent } from './pComparison/pc-global-score/pc-global-score.component';
import { PCMenuComponent } from './pComparison/pc-menu/pc-menu.component';
import { PCDimensionBusinessComponent } from './pComparison/dimension/pc-dimension-business/pc-dimension-business.component';
import { PCDimensionMenuComponent } from './pComparison/dimension/pc-dimension-menu/pc-dimension-menu.component';
import { PCDimensionContextualComponent } from './pComparison/dimension/pc-dimension-contextual/pc-dimension-contextual.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogModule } from '@angular/material/dialog';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrMenuComponent } from './pRecommendation/pr-menu/pr-menu.component';
import { PSAMenuComponent } from './pSelf-assessment/p-sa-menu/p-sa-menu.component';
import { PSaFeedbackProvisionComponent } from './pSelf-assessment/p-sa-feedback-provision/p-sa-feedback-provision.component';
import { PDmMenuComponent } from './data-management/p-dm-menu/p-dm-menu.component';
import { PDmSeeEditQuestionnaireComponent } from './data-management/p-dm-see-edit-questionnaire/p-dm-see-edit-questionnaire.component';
import { PDmRecommendationQuestionsComponent } from './data-management/p-dm-recommendation-questions/p-dm-recommendation-questions.component'
import { PCDimensionTechnicalComponent } from './pComparison/dimension/pc-dimension-technical/pc-dimension-technical.component';
import { ModalNoAuthComponent } from './components/modal-no-auth/modal-no-auth.component';
import { ModalNoPermisionsComponent } from './components/modal-no-permisions/modal-no-permisions.component';
import { PcTabComponent } from './pComparison/user/pc-tab/pc-tab.component';
import { PCUserMenuComponent } from './pComparison/user/pc-user-menu/pc-user-menu.component';
import { PDmQuestionsComponent } from './data-management/p-dm-questions/p-dm-questions.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PSaTabComponent } from './pSelf-assessment/p-sa-tab/p-sa-tab.component';
import { PSaQHistoryComponent } from './pSelf-assessment/p-sa-q-history/p-sa-q-history.component';
import { PlatformDetailsComponent } from './pRecommendation/platform-details/platform-details.component';
import { SafePipe } from './safe.pipe';
import { ShowCommentsComponent } from './pSelf-assessment/show-comments/show-comments.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    InfoComponent,
    ProfileComponent,
    PCGlobalScoreComponent,
    PCMenuComponent,
    PCDimensionTechnicalComponent,
    PCDimensionBusinessComponent,
    PCDimensionMenuComponent,
    PCDimensionContextualComponent,
    ModalNoAuthComponent,
    ModalNoPermisionsComponent,
    PageNotFoundComponent,
    PrMenuComponent,
    PSAMenuComponent,
    PSaFeedbackProvisionComponent,
    PDmMenuComponent,
    PDmSeeEditQuestionnaireComponent,
    PDmRecommendationQuestionsComponent,
    PcTabComponent,
    PCUserMenuComponent,
    PDmQuestionsComponent,
    PSaTabComponent,
    SafePipe,
    PSaQHistoryComponent,
    PlatformDetailsComponent,
    ShowCommentsComponent
  ],
  imports: [
    BrowserModule,
    UiModule,
    NgbModule,
    DragDropModule,
    ChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    MatDialogModule,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
  entryComponents: [ModalNoAuthComponent, ModalNoPermisionsComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

