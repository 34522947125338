import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'src/environments/environment';
import { ModelObject } from 'src/app/models/model-object';
import *  as constants from '../../utilities/constants';

@Injectable({
  providedIn: 'root'
})
export class DevelopersService {
  protected basePath = environment.PROD_URL_GATEWAY;
  protected basePathICSSservices = environment.PROD_URL_GATEWAY_T_SERVICES;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  private consumes: string[] = [
    'application/json'
  ];

  private httpHeaderAccepts: string[] = [
    'application/json'
  ];

  private defaultHeader() {
    let headers = this.defaultHeaders;

    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(this.httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(this.consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }
    return headers;
  }

  /*public getFeedbackQuestionnaires(body: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public getFeedbackQuestionnaires(body: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public getFeedbackQuestionnaires(body: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public getFeedbackQuestionnaires(body?: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<any>;
  public getFeedbackQuestionnaires(body: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    //let path = this.basePath + '/login';
    return this.httpClient.post<any>(path, body,
        {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
        }
    );
   
  }*/

  public getDeveloperQuestionTypes(): Observable<any> {
    let headers = this.defaultHeader();
    let path = this.basePathICSSservices + '/PU/T7';
    return this.httpClient.get<any>(path,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        reportProgress: false
      }
    );
  }

  public getDeveloperQuestionCategories(): Observable<any> {
    let headers = this.defaultHeader();
    let path = this.basePathICSSservices + '/PU/T6';
    return this.httpClient.get<any>(path,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        reportProgress: false
      }
    );
  }

  public getDeveloperQuestions(): Observable<any> {
    let headers = this.defaultHeader();
    let path = this.basePathICSSservices + '/PU/T2';
    return this.httpClient.get<any>(path,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        reportProgress: false
      }
    );
  }

  public saveNewAnswers(body): Observable<any> {
    let headers = this.defaultHeader();
    let path = this.basePathICSSservices + '/PU/T5';
    return this.httpClient.post<any>(path, body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        reportProgress: false
      }
    );
  }

  public getLasAnswerForDevQuestions(userId, platform_id): Observable<any> {
    let headers = this.defaultHeader();
    let path = this.basePathICSSservices + '/PU/T3' + "/" + userId + "/" + platform_id;
    return this.httpClient.get<any>(path,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        reportProgress: false
      }
    );
  }


  public getDevQAndRatingsByPlatformId(platform_id): Observable<any> {
    let headers = this.defaultHeader();
    let path = this.basePath + '/api/O10?platform_id=' + platform_id;

    return this.httpClient.get<any>(path,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        reportProgress: false
      }
    );
  }

  public getDevelopersComments(platform_id, developerquestion_id): Observable<any> {
    let headers = this.defaultHeader();
    let path = this.basePathICSSservices + '/PU/O11plus?platform_id=' + platform_id + "&developerquestion_id=" + developerquestion_id;
    return this.httpClient.get<any>(path,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        reportProgress: false
      }
    );
  }

  public getDevFeedbacksByPlatformId(platform_id): Observable<any> {
    let headers = this.defaultHeader();
    let path = this.basePath + '/api/O12?platform_id=' + platform_id;

    return this.httpClient.get<any>(path,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        reportProgress: false
      }
    );
  }

  public insertNewDeveloperQuestion(body: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public insertNewDeveloperQuestion(body: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public insertNewDeveloperQuestion(body: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public insertNewDeveloperQuestion(body: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling auth.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json; charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json; charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let path = this.basePathICSSservices + '/PU/T9';
    return this.httpClient.post<any>(path, body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public updateNewDeveloperQuestion(body: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateNewDeveloperQuestion(body: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateNewDeveloperQuestion(body: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateNewDeveloperQuestion(body: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling auth.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json; charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json; charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let path = this.basePathICSSservices + '/PU/T8';
    return this.httpClient.post<any>(path, body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public updateOrderDeveloperQuestions(body: ModelObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public updateOrderDeveloperQuestions(body: ModelObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public updateOrderDeveloperQuestions(body: ModelObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public updateOrderDeveloperQuestions(body: ModelObject, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling update order developer questions.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json; charset=utf-8'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json; charset=utf-8'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    let path = this.basePathICSSservices + '/PlatformUptake/T11';
    return this.httpClient.post<any>(path, body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  public getLastUserAnswersPerPlatformAndQuestion(token, platform_id, devQ_id): Observable<any> {
    let headers = this.defaultHeader();
    let path = this.basePathICSSservices + '/PU/T4/' + token + '/' + platform_id + '/' + devQ_id

    return this.httpClient.get<any>(path,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        reportProgress: false
      }
    );
  }
}
