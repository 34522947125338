import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { DevelopersDAOService } from 'src/app/api/DOA/developers-DAO.service';
import { DeveloperQuestionWithRatingsAndCharts, itemArrayCharts } from 'src/app/models/developer_question';
import { Platform } from 'src/app/models/platform';
import { DeveloperFeedbackQuestions, Rating, Rating_details } from 'src/app/models/ratings';
import { FunctionsComponent } from 'src/app/utilities/functions';


@Component({
  selector: 'app-p-sa-tab',
  templateUrl: './p-sa-tab.component.html',
  styleUrls: ['./p-sa-tab.component.css']
})
export class PSaTabComponent implements OnInit {
  @Input() platform: Platform;
  @Input() devQID: number;
  f = new FunctionsComponent()
  isLoadingRatings = true;
  isLoadingFeedback = true;
  loaded = false;
  showTab = true;
  devQSelected
  devQuestions: DeveloperQuestionWithRatingsAndCharts[]
  constructor(private devDOAService: DevelopersDAOService) {

  }

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          stepSize: 1,
          suggestedMin: 0,
          suggestedMax: 1,
        }
      }]
    },

    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }

  };

  ngOnInit() {
    this.devQuestions = this.f.getValidDeveloperQuestionsById(this.devQID)
    this.devQuestions.forEach(x => {
      let chartDataItemArray = {} as itemArrayCharts;
      chartDataItemArray.values = [{ data: [], fill: false, hoverBackgroundColor: "rgb(35, 166, 173)", backgroundColor: "rgb(35, 166, 173)", label: '' }];
      x.chart = chartDataItemArray
      x.comments = []
    })

    this.devDOAService.getDevQuestAndRatingsByPlatform(this.platform.platform_id).subscribe(
      response => {
        let list = response['developer_questions'];
        list.forEach(item => {
          const index = this.devQuestions.findIndex(x => x.devquestion_id === item.developerquestion_id);
          if (index != -1) {
            this.devQuestions[index].numberofratings = item['numberofratings']
            this.devQuestions[index].mean_rating = item['mean_rating']
            this.devQuestions[index].hastext = item.hastext
            if (item.hastext) {
              this.devDOAService.getDevelopersComments(this.platform.platform_id, item.developerquestion_id).subscribe(
                response => {
                  let comments = response["comments"]
                  this.devQuestions[index].comments = comments
                }
              )
            }
          }
        });
        this.isLoadingRatings = false;
        this.loaded = !this.isLoadingFeedback && !this.isLoadingRatings

      },
      error => {
        console.log("Error getDevQuestAndRatingsByPlatform: ", error);
      });

    this.devDOAService.getPlatformFeedback(this.platform.platform_id).subscribe(
      response => {
        let list = response['questions'];
        list.forEach(item => {
          const index = this.devQuestions.findIndex(x => x.devquestion_id === item.developerquestion_id);
          if (index != -1) {
            let devQuest: DeveloperFeedbackQuestions;
            devQuest = item;

            let chartDataItemArray = {} as itemArrayCharts;
            chartDataItemArray.values = [{ data: [], fill: false, hoverBackgroundColor: "rgb(35, 166, 173)", backgroundColor: "rgb(35, 166, 173)", label: '' }];

            let feedbackAnswers = devQuest.answers;
            let labelsArray: Label[] = new Array<string>();
            let valuesArray: number[] = [];
            chartDataItemArray.title = devQuest.developerquestion_title;
            feedbackAnswers.forEach(ans => {

              labelsArray.push(ans.answer_title)
              valuesArray.push(ans.answer_count)
            })

            chartDataItemArray.values[0]["data"] = valuesArray;
            chartDataItemArray.labels = labelsArray;
            this.devQuestions[index].chart = chartDataItemArray;
          }
        });
        this.isLoadingFeedback = false;
        this.loaded = !this.isLoadingFeedback && !this.isLoadingRatings
      },
      error => {
        this.isLoadingFeedback = false;
        this.loaded = !this.isLoadingFeedback && !this.isLoadingRatings
        console.log("Error getPlatformFeedback: ", error);
      })
  }

  getTemplate(rate) {
    if (rate < 2.5) {
      return "half_red"
    }
    else if (rate < 4) {
      return "half_gold"
    }
    else {
      return "half_green"
    }
  }

  showComments(devQ) {
    this.showTab = false
    this.devQSelected = devQ
  }

  cancelSeeAnswers() {
    this.showTab = true
    this.devQSelected = null
  }

}
