import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ContextualQDOAService } from 'src/app/api/DOA/contextual-q-doa.service';
import { KpiDoaService } from 'src/app/api/DOA/kpi-doa.service';
import { Period } from 'src/app/models/period';
import { Platform } from 'src/app/models/platform';
import { FunctionsComponent } from 'src/app/utilities/functions';
import *  as constants from '../../utilities/constants';

@Component({
  selector: 'app-platform-details',
  templateUrl: './platform-details.component.html',
  styleUrls: ['./platform-details.component.css']
})
export class PlatformDetailsComponent implements OnInit {
  @Output() cancelEmit = new EventEmitter();
  @Input() platform: Platform;
  loaded = false
  f = new FunctionsComponent()
  periodsList: Period[] = this.f.getValidKPIValuesPeriods();
  listKPIs = this.f.getTypeDimensionKPIsList(constants.KPI_dimension.T);
  kpisValues = []
  all_contextualQs;
  contextualQAnswers = []

  constructor(private kpiServices: KpiDoaService,
    private contextualQDoa: ContextualQDOAService) {
    this.contextualQDoa.getContextualQList().subscribe(
      response => {
        let list = response['contextual_questions'];

        list.sort(function (a, b) {
          return a.question_orderofappearance - b.question_orderofappearance;
        });
        let listValidContextQ = []

        list.forEach(cQ => {
          if (!cQ.flagged) {
            listValidContextQ.push(cQ)
          }
        })
        this.all_contextualQs = listValidContextQ;
        this.loadData()
      });
  }

  ngOnInit() {

  }

  cancel() {
    this.cancelEmit.emit();
  }

  loadData() {
    let dim_id = this.f.getKPIDimensionIdByAbbreviation('T');
    this.kpiServices.getKPIsValuesByPlatformPeriodDimension(this.platform.platform_id, this.periodsList[0].measurement_period_id, dim_id).
      subscribe(kpi_list => {
        let listValues = kpi_list['KPI_values'];
        this.contextualQDoa.getContextualQAnswers(this.platform.platform_id).
          subscribe(answers => {
            let answersContexQ = answers["platforms"][0].contextual_answers
            this.generateArrayData(listValues, answersContexQ)
          })

      })
  }

  generateArrayData(listValues, listAnswers) {
    this.contextualQAnswers = []
    let array = [];
    for (let i = 0; i < this.listKPIs.length; i++) {

      let kpi = this.listKPIs[i]
      let newKPI = {
        "KPI_id": kpi.kpi_id,
        "title": kpi.title,
        "normalized_value": "-",
        "original_value": "-",
        "unit": kpi.unit_of_measurement
      }

      for (let value of listValues) {

        if (kpi.kpi_id == value.kpi_id) {
          newKPI.normalized_value = value.normalized_value
          newKPI.original_value = value.original_value
        }
      }
      array.push(newKPI)
    }
    this.kpisValues = array

    for (let contextQ of this.all_contextualQs) {
      let ans = {
        question_answer_title: "-",
        question_title: contextQ.question_title
      }
      listAnswers.forEach(item => {
        if (item.question_id == contextQ.question_id) {
          ans.question_answer_title = item["question_answer_title"]
        }

      })
      this.contextualQAnswers.push(ans)
    }

    this.loaded = true

  }
}
