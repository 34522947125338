import { Component, OnInit } from '@angular/core';
import { PlatformsDOAService } from 'src/app/api/DOA/platforms-doa.service';
import *  as constants from '../../utilities/constants';
import { MatTableDataSource } from '@angular/material/table';
import { ContextualQDOAService } from 'src/app/api/DOA/contextual-q-doa.service';
import { EncryptedStorageService } from 'src/app/utilities/encryptedStorageService';
import { UserDOAService } from 'src/app/api/DOA/user-doa.service';
import { user } from '../../models/user';
import { Platform } from 'src/app/models/platform';
import { FunctionsComponent } from 'src/app/utilities/functions';

@Component({
  selector: 'app-pr-menu',
  templateUrl: './pr-menu.component.html',
  styleUrls: ['./pr-menu.component.css']
})
export class PrMenuComponent implements OnInit {
  loaded = false
  page1 = true;
  page2 = false;
  page3 = false;
  showRecommendationMenu = true;
  userWeights = []
  complete_page1 = false;
  complete_page2 = false;
  complete_page3 = false;
  user_expectations
  userExpt = new Array();
  recomendedPlatforms
  priorityAnswer = new Array();
  platformSelected
  //numWeights = Array(10).fill().map((x, i) => i)
  questionsPage1 = JSON.parse(this.secureStorage.decryptLocalSecureStorage(constants.lSN_contextualQuestions));
  f = new FunctionsComponent()
  KPIsList = this.f.getTypeDimensionKPIsList(constants.KPI_dimension.T);
  columnsTitlePage2: string[] = ['KPI_ID', 'KPI_description', 'score'];
  columnsTitlePage3: string[] = ['platform', 'score', 'view_detail'];
  platformsRecommended: MatTableDataSource<any>;

  constructor(private platformServices: PlatformsDOAService,
    private contextualQDoa: ContextualQDOAService,

    private secureStorage: EncryptedStorageService,
    private userDOA: UserDOAService) {
    this.userDOA.updateUserDetails()
    this.userDOA.getUserWeights().subscribe(response => {
      let currentUser: user = response;
      this.secureStorage.secureStorage(constants.user_info, JSON.stringify(currentUser))
      this.userWeights = currentUser.custom_KPI_weights;
      this.generateArrayPriorities();

    })
    this.loadData();

  }

  ngOnInit() {

  }


  addFormNames() {
    //const numQuestions = this.questionsPage1.length

    let arrayUser = [];
    this.questionsPage1.forEach(question => {
      let contextAnswe = {
        "contextual_question_id": question.question_id,
        "none": true,
        "all": false,
        "user_expectations": [],
      }
      arrayUser.push(contextAnswe)
    })
    this.userExpt = arrayUser;
    this.loaded = true;
  }

  loadData() {
    this.contextualQDoa.getContextualQList().subscribe(
      response => {
        let list = response['contextual_questions'];

        list.sort(function (a, b) {
          return a.question_orderofappearance - b.question_orderofappearance;
        });


        let listValidContextQ = []
        list.forEach(cQ => {
          if (!cQ.flagged) {
            let listAnswers = cQ.potential_answers;

            listAnswers.forEach(item => {
              item["checked"] = false
            })


            listAnswers.sort(function (a, b) {
              return a.answer_orderofappearance - b.answer_orderofappearance;
            });


            cQ.potential_answers = listAnswers;
            //cQ.potential_answers.push(listAnswers);
            cQ.originalOrder = cQ.question_orderofappearance;
            cQ.none = true
            cQ.all = false
            listValidContextQ.push(cQ)
          }
        })
        this.secureStorage.secureLocalStorage(constants.lSN_contextualQuestions, JSON.stringify(listValidContextQ))
        //this.initalList = JSON.parse(this.secureStorage.decryptLocalSecureStorage(constants.lSN_contextualQuestions));
        this.questionsPage1 = listValidContextQ;

        let orderList = [];
        list.forEach(cQ => { orderList.push(cQ.question_orderofappearance) });
        this.addFormNames()
      });
  }

  clickButton(page) {
    if (page == 'page1') {

      let listUSerExpt = []
      this.userExpt.forEach(it => {
        let uExp;
        if (it.none || it.all) {
          uExp = {
            contextual_question_id: it.contextual_question_id,
            indifferent: it.none || it.all,
            user_expectations: it.user_expectations
          }
          listUSerExpt.push(uExp)
        }
        else {
          if (it.user_expectations.length != 0) {
            uExp = {
              contextual_question_id: it.contextual_question_id,
              indifferent: it.none || it.all,
              user_expectations: it.user_expectations
            }
            listUSerExpt.push(uExp)
          }
        }
      })
      this.user_expectations = {
        user_expectations: listUSerExpt,
        user_id: this.userDOA.getToken()
      }
      this.page1 = false;
      this.complete_page1 = true;
      this.page2 = true;
      this.page3 = false;
    }
    else if (page == 'page2') {
      this.loaded = false
      let KPI_weights = [];
      this.priorityAnswer.forEach(weightUser => {
        if (weightUser.weight != null) {
          let weightToSend = {
            "KPI_id": weightUser.KPI_id,
            "weight": weightUser.weight
          }
          KPI_weights.push(weightToSend)
        }
        let index = this.userWeights.findIndex(x => x.kpi_id == weightUser.KPI_id)
        if (index != -1) {
          this.userWeights[index].kpi_weight = weightUser.weight
        }
      })
      let body = {
        "user_id": this.userDOA.getToken(),
        "tooltype_id": 2,
        "KPI_weights": KPI_weights
      }

      let user = JSON.parse(this.secureStorage.decryptSecureStorage(constants.user_info));
      user.custom_KPI_weights = this.userWeights
      this.secureStorage.secureStorage(constants.user_info, JSON.stringify(user))

      this.userDOA.insertNewUserWeights(body).subscribe(
        response => {
          this.userDOA.getUserRecomendations(this.user_expectations).subscribe(
            response => {
              this.loaded = true
              this.recomendedPlatforms = response["recommended_platforms"];


              this.recomendedPlatforms.forEach(x => {
                let score = x.score
                if (score == 'null') {
                  x.score = null
                }
                else {
                  x.score = Number(score)
                }
              })
              this.recomendedPlatforms.sort(function (a, b) {
                if (a.score === b.score)
                  return 0
                else if (a.score === null)
                  return 1
                else if (b.score === null)
                  return -1
                else
                  return b.score - a.score;
              });

              this.complete_page3 = true;
              this.getRecommendations()
            },
            error => {
              console.log("error", error)
              //this.statisticsService.updateStatisticsListInfo;
            });
        },
        error => {
          console.log("error", error)
          //this.statisticsService.updateStatisticsListInfo;
        });

      this.page2 = false;
      this.page1 = false;
      this.complete_page2 = true;
      this.page3 = true;
      //this.getRecommendations();
    }
  }

  goToPage(page) {
    if (page == 'page1') {
      this.page1 = true;
      this.complete_page1 = false;
      this.complete_page2 = false;
      this.page2 = false;
      this.page3 = false;
    }
    else if (page == 'page2') {
      this.loaded = false;
      this.page2 = true;
      this.complete_page2 = false;
      this.page1 = false;
      this.page3 = false;
      this.generateArrayPriorities();
    }
    else if (page == 'page3') {
      this.page2 = false;
      this.page1 = false;
      this.page3 = true;
    }
  }




  generateArrayPriorities() {
    let array = [];
    for (let i = 0; i < this.KPIsList.length; i++) {

      let kpi = this.KPIsList[i]
      let newKPI = {
        "KPI_id": kpi.kpi_id,
        "weight": 5,
        "title": kpi.title,
        "description": kpi.description
      }
      //array[this.KPIsList[i].kpi_id] = NaN
      for (let j = 0; j < this.userWeights.length; j++) {
        let weigth = this.userWeights[j];
        if (weigth.kpi_id == kpi.kpi_id) {
          newKPI.weight = weigth.kpi_weight
          break;
        }
      }

      array.push(newKPI)
    }

    this.priorityAnswer = array;
    this.loaded = this.page2
  }

  onCheckboxAll(event, quest, index) {
    this.userExpt[index].user_expectations = []
    if (event.checked) {
      quest.all = true;
      quest.none = false
      quest.potential_answers.forEach(x => {
        this.userExpt[index].user_expectations.push(x.answer_id)
        x.checked = true;
      })
      this.userExpt[index].all = true;
      this.userExpt[index].none = false;

    }
  }

  onCheckboxNone(event, quest, index) {
    quest.all = false
    quest.none = true
    quest.potential_answers.forEach(x => {
      x.checked = false;
    })
    this.userExpt[index].all = false;
    this.userExpt[index].none = true
    this.userExpt[index].user_expectations = []
  }

  onCheckboxChange(event, index, answer_id, quest) {
    //quest.indifferent = false;
    if (event.checked) {
      quest.none = false;
      this.userExpt[index].none = false

      // controlsCQ['indifferent'].value = false;
      quest.potential_answers.forEach(item => {
        if (item.answer_id == answer_id) {
          item.checked = true
        }
      })
      // this.userExpt[index].indifferent = false;
      this.userExpt[index].user_expectations.push(answer_id)
      if (this.userExpt[index].user_expectations.length == quest.potential_answers.length) {
        quest.all = true;
        this.userExpt[index].all = true;
      }
      else {
        quest.all = false;
        this.userExpt[index].all = false;
      }
    } else {
      quest.all = false;
      this.userExpt[index].all = false;
      quest.potential_answers.forEach(item => {
        if (item.answer_id == answer_id) {
          item.checked = false
        }
      })
      //this.userExpt[index].indifferent = false;
      const i = this.userExpt[index].user_expectations.findIndex(x => x === answer_id);
      this.userExpt[index].user_expectations.splice(i, 1);
      if (this.userExpt[index].user_expectations.length == 0) {
        quest.none = true;
        this.userExpt[index].none = true;
      }
      else {

        quest.none = false;
        this.userExpt[index].none = false;

      }
      // this.userExpt[index].user_expectations.removeAt(i)
      //controlsCQ['indifferent'].value = false;
      //  const user_expectations = (controlsCQ['user_expectations'] as FormArray);
      // const index = user_expectations.controls.findIndex(x => x.value === answer_id);
      // user_expectations.removeAt(index)

    }
  }


  getRecommendations() {
    this.platformsRecommended = new MatTableDataSource(this.recomendedPlatforms);
  }

  seeDetails(platform: Platform) {
    if (platform != null) {
      this.platformSelected = platform;
      this.showRecommendationMenu = false;
    }
  }

  cancelSeeDetails() {
    this.showRecommendationMenu = true;
    this.platformSelected = null;
  }
}
