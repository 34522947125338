import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DeveloperQuestionWithRatingsAndCharts } from 'src/app/models/developer_question';
import { FunctionsComponent } from 'src/app/utilities/functions';

@Component({
  selector: 'app-show-comments',
  templateUrl: './show-comments.component.html',
  styleUrls: ['./show-comments.component.css']
})
export class ShowCommentsComponent implements OnInit {
  @Input() devQ: DeveloperQuestionWithRatingsAndCharts;
  @Output() cancelEmit = new EventEmitter();
  f = new FunctionsComponent()

  loaded = true
  constructor() { }

  ngOnInit() {
  }

  getTemplate(rate) {
    if (rate < 2.5) {
      return "half_red"
    }
    else if (rate < 4) {
      return "half_gold"
    }
    else {
      return "half_green"
    }
  }

  cancel() {
    this.cancelEmit.emit();
  }
}
