
import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { DevelopersService } from 'src/app/api/api/developers.service';
import { DevelopersDAOService } from 'src/app/api/DOA/developers-DAO.service';
import { DeveloperQuestion, DeveloperQuestionHistory } from 'src/app/models/developer_question';
import { FunctionsComponent } from 'src/app/utilities/functions';
import { Platform } from 'src/app/models/platform';
import { developer_answers } from 'src/app/models/developers_answers';

@Component({
  selector: 'app-p-sa-feedback-provision',
  templateUrl: './p-sa-feedback-provision.component.html',
  styleUrls: ['./p-sa-feedback-provision.component.css']
})
export class PSaFeedbackProvisionComponent implements OnInit {
  @Input() platform: Platform;
  f = new FunctionsComponent()
  somethingToSave = false;
  msg_save;
  previousAnswers: developer_answers[] = []
  categories_of_questions = this.f.getCategoriesDevelopersQuestions()
  devQuestions: DeveloperQuestion[] = this.f.getValidDeveloperQuestions()
  questionsFeedback: developer_answers[] = []
  type_of_questions = this.f.getTypesDeveloperQuestions();
  loaded = false;
  showQuestionnaire = true;
  devQSelected: DeveloperQuestionHistory;
  devQChangedIds = []
  constructor(private developerQDAO: DevelopersDAOService,
    private developerQService: DevelopersService) {
  }

  ngOnInit() {
  }

  loadData() {
    this.developerQService.getDeveloperQuestions().subscribe(
      response => {
        let listDevelopersQuestions = []
        this.questionsFeedback = []
        this.previousAnswers = []
        let listTemp = response['developer_questions'];
        listTemp.forEach(question => {
          if (!question.flagged) {
            listDevelopersQuestions.push(question)
          }
        })
        //let listDevelopersQuestions = response['developer_questions'];
        listDevelopersQuestions.sort(function (a, b) {
          return a.devquestion_orderofappearance - b.devquestion_orderofappearance;
        });

        listDevelopersQuestions.forEach(devQ => {

          if (devQ.potential_answers != null) {
            devQ.potential_answers.sort(function (a, b) {
              return a.answer_orderofappearance - b.answer_orderofappearance;
            });
          }
          let devQuestAnswers: developer_answers = {
            dev_rating: 0,
            dev_structured_answers: [],
            dev_text: '',
            devquestion_id: devQ.devquestion_id,
            othertext: '',
            otheranswer: devQ.otheranswer,
            devquestion_title: devQ.devquestion_title,
            typeOfQuestion: this.getTypeOfQuestion(devQ.devquestion_type_id),
            potential_answers: devQ.potential_answers,
            orderofappearance: devQ.devquestion_orderofappearance,
            nrofanswers: devQ.nrofanswers,
            num_answers_selected: 0,
            has_selected_otheranswer: false
          }
          let devQuestAnswersOrigin: developer_answers = {
            dev_rating: 0,
            dev_structured_answers: [],
            dev_text: '',
            devquestion_id: devQ.devquestion_id,
            othertext: '',
            otheranswer: devQ.otheranswer,
            devquestion_title: devQ.devquestion_title,
            typeOfQuestion: this.getTypeOfQuestion(devQ.devquestion_type_id),
            potential_answers: devQ.potential_answers,
            orderofappearance: devQ.devquestion_orderofappearance,
            nrofanswers: devQ.nrofanswers,
            num_answers_selected: 0,
            has_selected_otheranswer: false

          }
          this.questionsFeedback.push(devQuestAnswers)
          this.previousAnswers.push(devQuestAnswersOrigin)
        })
        this.devQuestions = listDevelopersQuestions
        this.getLastAnswers()
      });

    this.developerQService.getDeveloperQuestionCategories().subscribe(
      response => {
        let listDeveloperQCategories = response['developer_question_categories'];
        this.categories_of_questions = listDeveloperQCategories
      });

    this.developerQService.getDeveloperQuestionTypes().subscribe(
      response => {
        let listDeveloperQTypes = response['developer_question_types'];
        this.type_of_questions = listDeveloperQTypes
      });

    this.updateStorage()
  }

  refreshPageNewPlatform() {

  }

  getLastAnswers() {
    this.developerQDAO.getLastAnswerDevelopersQuestionsPerPlatform(this.platform.platform_id).subscribe(response => {
      let developer_answers = response['developer_answers'];
      developer_answers.forEach(item => {
        const index = this.questionsFeedback.findIndex(x => x.devquestion_id === item.devquestion_id);
        if (index != -1) {
          if (item.dev_rating != null) {
            this.questionsFeedback[index].dev_rating = item.dev_rating
            this.previousAnswers[index].dev_rating = item.dev_rating
          }

          if (item.dev_structured_answers != null) {
            let structuredAnswersWithO = []
            item.dev_structured_answers.forEach(x => {
              if (x != 0) {
                structuredAnswersWithO.push(x)
              }
            })
            this.questionsFeedback[index].dev_structured_answers = structuredAnswersWithO
            this.previousAnswers[index].dev_structured_answers = structuredAnswersWithO
            this.questionsFeedback[index].num_answers_selected = structuredAnswersWithO.length
            this.previousAnswers[index].num_answers_selected = structuredAnswersWithO.length
          }

          if (item.dev_text != null) {
            this.questionsFeedback[index].dev_text = item.dev_text
            this.previousAnswers[index].dev_text = item.dev_text
          }

          if (item.othertext != null && item.othertext != "") {
            this.questionsFeedback[index].othertext = item.othertext
            this.previousAnswers[index].othertext = item.othertext
            this.questionsFeedback[index].num_answers_selected++
            this.previousAnswers[index].num_answers_selected++
            this.questionsFeedback[index].has_selected_otheranswer = true && this.questionsFeedback[index].otheranswer;
            this.previousAnswers[index].has_selected_otheranswer = true && this.questionsFeedback[index].otheranswer;

          }
        }
      })
      this.loaded = true;
    })
  }

  updateStorage() {
    this.developerQDAO.updateDeveloperQuestionTypes();
    this.developerQDAO.updateDevelopersQuestions();
    this.developerQDAO.updateDevelopersQuestionsCategories();
  }

  getTypeOfQuestion(id) {
    return this.f.getTypeAbbDevelopersQuestionsById(id)
  }

  ngOnChanges(changes: SimpleChange) {
    this.cancel()
  }

  saveAnswers() {
    this.somethingToSave = false;
    this.loaded = false;
    let body = []

    this.questionsFeedback.forEach(feedback => {
      this.devQChangedIds.forEach(x => {
        if (feedback.devquestion_id == x) {
          let item = {
            dev_rating: feedback.dev_rating,
            dev_structured_answers: feedback.dev_structured_answers,
            dev_text: feedback.dev_text,
            devquestion_id: feedback.devquestion_id,
            othertext: feedback.othertext
          }
          body.push(item)
        }
      })
    })
    this.devQChangedIds = []
    let developer_answers = {
      changed_developer_answers: body,
      platform_id: this.platform.platform_id
    }
    this.developerQDAO.saveAnswers(developer_answers).subscribe(response => {
      this.loaded = true;
      this.msg_save = "Answers have been saved successfully"
    })
  }

  cancel() {
    this.devQSelected = null
    this.showQuestionnaire = true
    this.msg_save = null;
    this.loaded = false;
    this.devQChangedIds = []
    this.somethingToSave = false
    this.loadData()
  }

  isChecked(devQ: developer_answers, answerid) {
    const index = devQ.dev_structured_answers.findIndex(x => x === answerid);
    if (index != -1) {
      return true
    }
    else
      return false
  }

  addAnswersUpdateID(devQIn) {
    const index = this.devQChangedIds.findIndex(x => x === devQIn.devquestion_id);
    if (index == -1)
      this.devQChangedIds.push(devQIn.devquestion_id)
  }

  onCheckboxChange(event: any, devQIn: developer_answers) {
    this.msg_save = null;

    let maxAnswers = devQIn.nrofanswers;
    if (event.source.value == "OTHER") {
      if (event.checked) {
        if (devQIn.num_answers_selected < maxAnswers) {
          this.somethingToSave = true;
          this.addAnswersUpdateID(devQIn)
          devQIn.num_answers_selected++
          devQIn.has_selected_otheranswer = true
        }
        else {
          alert("You can select maximum of " + maxAnswers + " checkbox.");
          event.source.checked = false;
        }
      }
      else {
        devQIn.has_selected_otheranswer = false
        devQIn.num_answers_selected--
      }
    }
    else {
      let previousAnswers = devQIn.dev_structured_answers;

      if (event.checked) {
        if (devQIn.num_answers_selected < maxAnswers) {
          this.somethingToSave = true;
          this.addAnswersUpdateID(devQIn)
          devQIn.dev_structured_answers.push(event.source.value)
          devQIn.num_answers_selected++
        }
        else {
          alert("You can select maximum of " + maxAnswers + " checkbox.");
          event.source.checked = false;
        }
      } else {
        const index = previousAnswers.findIndex(x => x === event.source.value);
        if (index != -1) {
          devQIn.num_answers_selected--
          devQIn.dev_structured_answers.splice(index, 1);
          if (devQIn.num_answers_selected > 0) {
            this.somethingToSave = true;
            this.addAnswersUpdateID(devQIn)
          }
          else {
            this.somethingToSave = !this.hasChanged();
            if (this.somethingToSave) {
              this.addAnswersUpdateID(devQIn)
            }
          }
        }
      }
    }
  }

  onChangeText(questD: developer_answers) {
    this.msg_save = null;
    if (questD.dev_text != '') {
      this.somethingToSave = true;
    }
    else {
      this.somethingToSave = !this.hasChanged();
    }

    if (this.somethingToSave) {
      this.addAnswersUpdateID(questD)
    }
  }

  onChangeStart(questD: developer_answers) {
    if (questD != null && questD.dev_rating != null) {
      this.msg_save = null;
      this.somethingToSave = true;
      this.addAnswersUpdateID(questD)
    }

  }

  onRadioButtonChange(event: any, devQIn: developer_answers) {
    if (event.value == "OTHER") {
      devQIn.has_selected_otheranswer = true
    }
    else {
      devQIn.has_selected_otheranswer = false
    }
    this.msg_save = null;
    devQIn.dev_structured_answers[0] = event.value
    this.somethingToSave = true;
    this.addAnswersUpdateID(devQIn)

  }

  hasChanged() {
    return JSON.stringify(this.previousAnswers) === JSON.stringify(this.questionsFeedback);
  }

  showHistory(devQ: DeveloperQuestionHistory) {
    this.devQSelected = devQ
    this.showQuestionnaire = false
  }

  showQ() {
    this.devQSelected = null
    this.showQuestionnaire = true
  }

}
