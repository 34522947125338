import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DevelopersService } from '../api/developers.service';
import { EncryptedStorageService } from 'src/app/utilities/encryptedStorageService';
import *  as constants from '../../utilities/constants';

@Injectable({
  providedIn: 'root'
})
export class DevelopersDAOService {
  developerQService: DevelopersService
  constructor(private http: HttpClient,
    private secureStorage: EncryptedStorageService) {
    this.developerQService = new DevelopersService(this.http, null, null);
  }


  getToken() {
    let user_id = this.secureStorage.decryptSecureStorage(constants.user_id);
    return user_id;

  }

  /*getFeedbackQuestionnaires() {
    let token = this.getToken();
    let service: DevelopersService = new DevelopersService(this.http, null, null);
    let forcedHeaders = service.defaultHeaders;
    const httpHeaderAuthorizationSelected: string | undefined = "Bearer " + token;
    // Authorization header
    forcedHeaders = forcedHeaders.set('Authorization', httpHeaderAuthorizationSelected);
    // Re-assign of the new 'defaultHeaders'
    service.defaultHeaders = forcedHeaders;
 
    return service.getFeedbackQuestionnaires();
  };*/

  updateDevelopersQuestionsCategories() {
    let listDeveloperQCategories = [];
    this.developerQService.getDeveloperQuestionCategories().subscribe(
      response => {
        listDeveloperQCategories = response['developer_question_categories'];
        this.secureStorage.secureLocalStorage(constants.lSN_developer_question_categories, JSON.stringify(listDeveloperQCategories))

      });
  }

  public updateDeveloperQuestionTypes() {
    let listDeveloperQTypes = [];
    this.developerQService.getDeveloperQuestionTypes().subscribe(
      response => {
        listDeveloperQTypes = response['developer_question_types'];
        this.secureStorage.secureLocalStorage(constants.lSN_developer_question_types, JSON.stringify(listDeveloperQTypes))
      });
  }

  public updateDevelopersQuestions() {
    let listDevelopersQuestions = [];
    this.developerQService.getDeveloperQuestions().subscribe(
      response => {
        listDevelopersQuestions = response['developer_questions'];
        this.secureStorage.secureLocalStorage(constants.lSN_developerQuestions, JSON.stringify(listDevelopersQuestions))
      });
  }

  saveAnswers(body) {
    body['user_id'] = this.getToken()
    return this.developerQService.saveNewAnswers(JSON.stringify(body))
  }


  getLastAnswerDevelopersQuestionsPerPlatform(platform_id) {
    let token = this.getToken()
    return this.developerQService.getLasAnswerForDevQuestions(token, platform_id)
  }

  insertNewDeveloperQuestion(body) {
    return this.developerQService.insertNewDeveloperQuestion(body)
  }

  updateNewDeveloperQuestion(body) {
    return this.developerQService.updateNewDeveloperQuestion(body)
  }

  getDevelopersComments(platform_id, developerquestion_id) {
    return this.developerQService.getDevelopersComments(platform_id, developerquestion_id);
  }

  getDevQuestAndRatingsByPlatform(platform_id) {
    return this.developerQService.getDevQAndRatingsByPlatformId(platform_id);
  }

  getPlatformFeedback(platform_id) {
    return this.developerQService.getDevFeedbacksByPlatformId(platform_id);
  }

  updateOrderDeveloperQuestions(body) {
    return this.developerQService.updateOrderDeveloperQuestions(body)
  }

  getLastAnswersPerPlatformAndQuestion(platform_id, questD_id) {
    let token = this.getToken()
    return this.developerQService.getLastUserAnswersPerPlatformAndQuestion(token, platform_id, questD_id);
  }
}
