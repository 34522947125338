import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';

@Component({
  selector: 'app-p-dm-menu',
  templateUrl: './p-dm-menu.component.html',
  styleUrls: ['./p-dm-menu.component.css']
})
export class PDmMenuComponent implements OnInit {
  selected_tab_see_edit = true;
  selected_tab_new_quest = false;
  selected_tab_recom_quest = false;
  constructor() { }

  ngOnInit() {
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    if (tabChangeEvent.index == 0) {
      this.selected_tab_see_edit = true;
      this.selected_tab_new_quest = false;
      this.selected_tab_recom_quest = false;
    }
    else if (tabChangeEvent.index == 1) {
      this.selected_tab_see_edit = false;
      this.selected_tab_new_quest = true;
      this.selected_tab_recom_quest = false;
    }
    else if (tabChangeEvent.index == 2) {
      this.selected_tab_see_edit = false;
      this.selected_tab_new_quest = false;
      this.selected_tab_recom_quest = true;
    }
  }
}
