import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { DevelopersDAOService } from 'src/app/api/DOA/developers-DAO.service';
import { DeveloperQuestionHistory } from 'src/app/models/developer_question';
import { Platform } from 'src/app/models/platform';
import { FunctionsComponent } from 'src/app/utilities/functions';

@Component({
  selector: 'app-p-sa-q-history',
  templateUrl: './p-sa-q-history.component.html',
  styleUrls: ['./p-sa-q-history.component.css']
})
export class PSaQHistoryComponent implements OnInit {
  @Input() platform: Platform;
  @Input() devQ: DeveloperQuestionHistory;
  @Output() emitCancel = new EventEmitter();

  f = new FunctionsComponent()
  developer_answers

  constructor(private devDOAService: DevelopersDAOService) { }

  ngOnInit() {
    this.devDOAService.getLastAnswersPerPlatformAndQuestion(this.platform.platform_id, this.devQ.devquestion_id).subscribe(response => {
      this.developer_answers = response['developer_answers']
    })
  }

  cancel() {
    this.emitCancel.emit();
  }

  getPotentialAnswerTitle(structuredanswer_id) {
    let index = this.devQ.potential_answers.findIndex(x => x.structuredanswer_id === structuredanswer_id);
    if (index != -1) {
      return this.devQ.potential_answers[index].answer_title
    }
  }

}
