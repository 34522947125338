import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { ProfileComponent } from './profile/profile.component';
import { PCMenuComponent } from './pComparison/pc-menu/pc-menu.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrMenuComponent } from './pRecommendation/pr-menu/pr-menu.component';
import { PSAMenuComponent } from './pSelf-assessment/p-sa-menu/p-sa-menu.component';
import { PDmMenuComponent } from './data-management/p-dm-menu/p-dm-menu.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data: {}
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
    }
  },
  {
    path: 'recommendation',
    component: PrMenuComponent,
    canActivate: [AuthGuard],
    data: {
    }
  },
  {
    path: 'comparison',
    component: PCMenuComponent,
    canActivate: [AuthGuard],
    data: {
    }
  },
  {
    path: 'self-assessment',
    component: PSAMenuComponent,
    canActivate: [AuthGuard],
    data: {
    }
  },
  {
    path: 'data_management',
    component: PDmMenuComponent,
    canActivate: [AuthGuard],
    data: {
    }
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: {
    }
  },
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
