import { Component, OnInit } from '@angular/core';
import { MatRadioChange } from '@angular/material';
import { DevelopersService } from 'src/app/api/api/developers.service';
import { DevelopersDAOService } from 'src/app/api/DOA/developers-DAO.service';
import { DeveloperPotentialAnswer } from 'src/app/models/developers_potential_answer';
import { DeveloperQuestion } from 'src/app/models/developer_question';
import { FunctionsComponent } from '../../utilities/functions';
import { moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-p-dm-questions',
  templateUrl: './p-dm-questions.component.html',
  styleUrls: ['./p-dm-questions.component.css']
})
export class PDmQuestionsComponent implements OnInit {
  newQuestion: boolean = false;
  errorMsg = "";
  showQuestionForm = false;
  error = '';
  submitted = false;
  isMultiple = false;
  showSaveButton = false;
  saveNewQuestion = false
  loaded = false;
  otherAnswerNum = 0
  maxAnwers = 0

  f = new FunctionsComponent()
  type_of_questions = this.f.getTypesDeveloperQuestions();
  type_of_questionsPlat = []
  type_of_questionsDevOrSol

  categories_of_questions = this.f.getCategoriesDevelopersQuestions()
  all_DevelopersQs: DeveloperQuestion[] = this.f.getDeveloperQuestions()
  devQSelected: DeveloperQuestion

  constructor(
    private developerQDAO: DevelopersDAOService,
    private developerQService: DevelopersService) {
    this.loadData();
    this.type_of_questions.forEach(x => {
      if (x.devq_type_abbreviation == 'R' || x.devq_type_abbreviation == 'RT' || x.devq_type_abbreviation == 'T') {

        this.type_of_questionsPlat.push(x)
      }
      if (x.devq_type_abbreviation == 'S') {
        this.type_of_questionsDevOrSol = x
      }
    })
  }

  ngOnInit() {
  }

  loadData() {
    this.developerQService.getDeveloperQuestions().subscribe(
      response => {
        let listDevelopersQuestions = response['developer_questions'];
        listDevelopersQuestions.sort(function (a, b) {
          return a.devquestion_orderofappearance - b.devquestion_orderofappearance;
        });

        listDevelopersQuestions.forEach(cQ => {
          cQ.originalOrder = cQ.devquestion_orderofappearance;
        })

        this.all_DevelopersQs = listDevelopersQuestions
        this.loaded = true;
      });

    this.developerQService.getDeveloperQuestionCategories().subscribe(
      response => {
        let listDeveloperQCategories = response['developer_question_categories'];
        this.categories_of_questions = listDeveloperQCategories
      });

    this.developerQService.getDeveloperQuestionTypes().subscribe(
      response => {
        let listDeveloperQTypes = response['developer_question_types'];
        this.type_of_questions = listDeveloperQTypes
      });

    this.updateStorage()
  }

  updateStorage() {
    this.developerQDAO.updateDeveloperQuestionTypes();
    this.developerQDAO.updateDevelopersQuestions();
    this.developerQDAO.updateDevelopersQuestionsCategories();
  }

  addNewQuestion() {
    // let body = {} as DeveloperQuestion;
    this.devQSelected = {} as DeveloperQuestion;
    this.showQuestionForm = true;
    this.errorMsg = "";
    this.newQuestion = true;
    this.devQSelected.potential_answers = [];
    this.addNewPotentialAnswers(1);
  }

  cancel() {
    this.saveNewQuestion = false;
    this.errorMsg = "";
    this.devQSelected = null
    this.showQuestionForm = false;
    this.newQuestion = false;
    this.loadData();
    this.isMultiple = false;
  }

  edit(quest) {
    this.devQSelected = quest
    this.isMultiple = false;
    this.showQuestionForm = true;
    this.errorMsg = "";
    this.newQuestion = false;
    let answers = quest.potential_answers;

    if (answers != null) {
      this.devQSelected.potential_answers = []
      answers.forEach(ans => {

        let answerNew = {
          structuredanswer_title: ans['answer_title'],
          structuredanswer_id: ans.structuredanswer_id,
          answer_orderofappearance: ans.answer_orderofappearance
        }

        this.devQSelected.potential_answers.push(answerNew)
      })
    }

    if (this.devQSelected.potential_answers != null) {
      this.devQSelected.potential_answers.sort(function (a, b) {
        return a.answer_orderofappearance - b.answer_orderofappearance;
      });
    }

    if (this.f.getTypeAbbDevelopersQuestionsById(quest.devquestion_type_id) == 'S') {
      this.isMultiple = true;

      //this.devQSelected.potential_answers = [];
      if (this.devQSelected.potential_answers == null || this.devQSelected.potential_answers.length == 0) {
        this.addNewPotentialAnswers(1);
      }
    }
    this.hasCompletedForm()
  }

  save() {
    this.saveNewQuestion = false;
    this.loaded = false;
    if (this.f.getCategoryAbbDevelopersQuestionsById(this.devQSelected.devquestion_category_id) == 'Plat') {
      this.devQSelected.potential_answers = []
      this.devQSelected.nrofanswers = 0;
      this.devQSelected.otheranswer = false
    }
    if (this.newQuestion) {
      let order = this.all_DevelopersQs.length + 1;
      this.devQSelected.devquestion_orderofappearance = order;

      let potentialAnswers = []
      this.devQSelected.potential_answers.forEach(x => {
        let poteAns = {
          "answer_orderofappearance": x.answer_orderofappearance,
          "structuredanswer_title": x.structuredanswer_title,
          "structuedanswer_id": x.structuredanswer_id
        }
        potentialAnswers.push(poteAns)
      })

      let body = {
        "devquestion_category_id": this.devQSelected.devquestion_category_id,
        "devquestion_id": this.devQSelected.devquestion_id,
        "devquestion_orderofappearance": this.devQSelected.devquestion_orderofappearance,
        "devquestion_title": this.devQSelected.devquestion_title,
        "devquestion_type_id": this.devQSelected.devquestion_type_id,
        "nrofanswers": this.devQSelected.nrofanswers,
        "flagged": false,
        "otheranswer": this.devQSelected.otheranswer,
        "potential_answers": potentialAnswers
      }

      this.developerQDAO.insertNewDeveloperQuestion(body).subscribe(
        response => {
          this.errorMsg = "";
          this.showQuestionForm = false;
          this.newQuestion = false;
          this.loadData();
          //this.changeView();
          //this.statisticsService.updateStatisticsListInfo;
        },
        error => {
          this.errorMsg = "Status: " + error.status + " error msg: " + error.error.msg
          console.log("error", error)
          //this.statisticsService.updateStatisticsListInfo;
        });
    }
    else {
      let potentialAnswers = []

      if (this.devQSelected.potential_answers != null) {
        this.devQSelected.potential_answers.forEach(x => {
          let poteAns = {
            "answer_orderofappearance": x.answer_orderofappearance,
            "structuredanswer_title": x.structuredanswer_title,
            "structuedanswer_id": x.structuredanswer_id
          }
          potentialAnswers.push(poteAns)
        })
      }
      let body = {
        "devquestion_category_id": this.devQSelected.devquestion_category_id,
        "devquestion_id": this.devQSelected.devquestion_id,
        "devquestion_orderofappearance": this.devQSelected.devquestion_orderofappearance,
        "devquestion_title": this.devQSelected.devquestion_title,
        "devquestion_type_id": this.devQSelected.devquestion_type_id,
        "nrofanswers": this.devQSelected.nrofanswers,
        "flagged": this.devQSelected.flagged,
        "otheranswer": this.devQSelected.otheranswer,
        "potential_answers": potentialAnswers
      }

      this.developerQDAO.updateNewDeveloperQuestion(body).subscribe(
        response => {
          this.errorMsg = "";
          this.showQuestionForm = false;
          this.newQuestion = false;
          this.loadData();
          //this.changeView();
          //this.statisticsService.updateStatisticsListInfo;
        },
        error => {
          this.errorMsg = "Status: " + error.status + " error msg: " + error.error.msg;
          console.log("error", error)
          //this.statisticsService.updateStatisticsListInfo;
        });
    }
  }


  radioChangeCategories(event: MatRadioChange) {
    this.hasCompletedForm()
    if (this.f.getCategoryAbbDevelopersQuestionsById(event.value) == "Plat") {
      this.isMultiple = false;
    }
    else {
      if (this.devQSelected.devquestion_type_id != null && this.f.getTypeDeveloperQ(this.devQSelected.devquestion_type_id) == "S") {
        this.isMultiple = true;
      }
      else {
        this.isMultiple = false
      }
    }
  }


  radioChangeTypes(event: MatRadioChange) {
    this.hasCompletedForm()
    if (this.f.getTypeDeveloperQ(event.value) == "S") {
      //if (event.value == "multiple") {
      this.isMultiple = true;
    }
    else {
      this.isMultiple = false;
    }
  }

  removePotentialAnswers(i) {
    this.devQSelected.potential_answers.splice(i, 1);
    this.hasCompletedForm()
  }


  addNewPotentialAnswers(num) {
    if (this.devQSelected.potential_answers == null) {
      this.devQSelected.potential_answers = []
    }
    let order = this.devQSelected.potential_answers.length;
    for (var i = 0; i < num; i++) {
      let newPotentialAnswer = {} as DeveloperPotentialAnswer;
      newPotentialAnswer.answer_orderofappearance = i + 1 + order;
      this.devQSelected.potential_answers.push(newPotentialAnswer)
    }
    this.maxAnwers = this.devQSelected.potential_answers.length + this.otherAnswerNum
    this.hasCompletedForm()
  }

  cancelOrder() {
    this.showSaveButton = false;
    this.loadData();
  }

  drop(event) {
    this.showSaveButton = true;
    moveItemInArray(this.all_DevelopersQs, event.previousIndex, event.currentIndex);
    let index = 1;
    this.all_DevelopersQs.forEach(developerQ => {
      developerQ.devquestion_orderofappearance = index;
      index++;
    })
  }

  orderAnswers(event) {
    moveItemInArray(this.devQSelected.potential_answers, event.previousIndex, event.currentIndex);
    let index = 1;
    this.devQSelected.potential_answers.forEach(pAnswer => {
      pAnswer.answer_orderofappearance = index;
      index++;
    })
  }

  getCategoryQuestion(id) {
    return this.f.getCategoryTitleDevelopersQuestionsById(id)
  }

  getTypeQuestion(id) {
    return this.f.getTypeTitleDevelopersQuestionsById(id)
  }

  saveOrder() {
    this.showSaveButton = false;
    // let contextQToUpdateOrders = {} as saveOrderArrayCQ;

    let arrayOrder = []
    this.all_DevelopersQs.forEach(devQuest => {
      let itemOrden = {
        "question_id": devQuest.devquestion_id,
        "orderofappearance": devQuest.devquestion_orderofappearance,
      }
      arrayOrder.push(itemOrden)
    })
    let developersQuestionsOrder = {
      questions_order: arrayOrder
    }

    this.developerQDAO.updateOrderDeveloperQuestions(developersQuestionsOrder).subscribe(
      response => {
        this.loadData();
      },
      error => {
        console.log("error", error)
        //this.statisticsService.updateStatisticsListInfo;
      });
  }

  isTitleComplete(): boolean {
    return this.devQSelected.devquestion_title != null && this.devQSelected.devquestion_title != ""
  }

  isTypeCorrect() {
    if (this.devQSelected.devquestion_category_id != null && this.devQSelected.devquestion_type_id != null) {
      if (this.f.getCategoryAbbDevelopersQuestionsById(this.devQSelected.devquestion_category_id) == 'Plat') {
        if (this.f.getTypeAbbDevelopersQuestionsById(this.devQSelected.devquestion_type_id) != 'S') {
          return true;
        }
        else {
          return false
        }
      }
      else if (this.f.getCategoryAbbDevelopersQuestionsById(this.devQSelected.devquestion_category_id) != 'Plat') {
        if (this.f.getTypeAbbDevelopersQuestionsById(this.devQSelected.devquestion_type_id) == 'S') {
          return true;
        }
        else {
          return false
        }
      }
      else {
        return false
      }
    }
    else {
      return false
    }
  }


  onChangeText() {
    this.hasCompletedForm()
  }

  hasCompletedForm() {
    this.errorMsg = null
    if (this.isTitleComplete() && this.isTypeCorrect()) {
      if (this.f.getTypeAbbDevelopersQuestionsById(this.devQSelected.devquestion_type_id) == 'S') {

        if (this.devQSelected.potential_answers != [] && this.devQSelected.nrofanswers != null) {
          let allPossibleAnswersComplete = true;
          let maxanswerValid = true;
          let maxAnswers = 0
          if (this.devQSelected.potential_answers != null) {
            this.devQSelected.potential_answers.forEach(x => {
              if (x.structuredanswer_title == null || x.structuredanswer_title == '') {
                allPossibleAnswersComplete = false;
                //this.saveNewQuestion = false;
              }
            })


            maxAnswers = this.devQSelected.potential_answers.length + (this.devQSelected.otheranswer ? 1 : 0)
          }
          if (this.devQSelected.nrofanswers > maxAnswers) {
            this.errorMsg = "The maximum number of answers must be less or equal than the number of possible answers (" + maxAnswers + ")"
            maxanswerValid = false;
          }
          else if (this.devQSelected.nrofanswers < 1) {
            this.errorMsg = "The minimum number of responses must be at least 1"
            maxanswerValid = false;
          }
          this.saveNewQuestion = allPossibleAnswersComplete && maxanswerValid
        }
        else {
          this.saveNewQuestion = false;
        }
      }
      else {
        this.saveNewQuestion = true
      }
    }
    else {
      this.saveNewQuestion = false
    }
  }

  onChangeOtherAnswer() {
    if (this.devQSelected.otheranswer)
      this.otherAnswerNum = 1
    else {
      this.otherAnswerNum = 0
    }
    this.hasCompletedForm()
  }

  checkReasonToNotSave() {
    this.errorMsg = null;
    if (!this.isTitleComplete()) {
      this.errorMsg = "Missing info: You must complete the question title"
    }
    else if (this.devQSelected.devquestion_category_id == null) {
      this.errorMsg = "You must select the question category"
    }
    else if (this.devQSelected.devquestion_type_id == null || !this.isTypeCorrect()) {
      this.errorMsg = "You must select the question type"
    }
    else if (this.f.getTypeAbbDevelopersQuestionsById(this.devQSelected.devquestion_type_id) == 'S') {
      let allPossibleAnswersComplete = true;
      if (this.devQSelected.potential_answers != []) {

        this.devQSelected.potential_answers.forEach(x => {
          if (x.structuredanswer_title == null || x.structuredanswer_title == '') {
            allPossibleAnswersComplete = false;
          }
        })
        if (!allPossibleAnswersComplete) {
          this.errorMsg = "You must complete all the title of the possible answers"
        }
      }

      if (allPossibleAnswersComplete && this.devQSelected.nrofanswers != null) {
        let maxAnswers = this.devQSelected.potential_answers.length + (this.devQSelected.otheranswer ? 1 : 0)
        if (this.devQSelected.nrofanswers > maxAnswers) {

          this.errorMsg = "The maximum number of answers must be less or equal than the number of possible answers (" + maxAnswers + ")"
        }
        else if (this.devQSelected.nrofanswers < 1) {
          this.errorMsg = "The minimum number of responses must be at least 1"
        }
      }
      else if (allPossibleAnswersComplete && this.devQSelected.nrofanswers == null) {
        this.errorMsg = "You must complete the maximun number of answers";
      }
    }
  }
}
