import { Component, OnInit } from '@angular/core';
import *  as constants from '../../utilities/constants';
import { MatTabChangeEvent } from '@angular/material';
import { EncryptedStorageService } from 'src/app/utilities/encryptedStorageService';
import { FunctionsComponent } from 'src/app/utilities/functions';
import { Platform } from 'src/app/models/platform';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-p-sa-menu',
  templateUrl: './p-sa-menu.component.html',
  styleUrls: ['./p-sa-menu.component.css']
})
export class PSAMenuComponent implements OnInit {
  platforms: Platform[] = JSON.parse(this.secureStorage.decryptLocalSecureStorage(constants.lSN_platformsList));
  f = new FunctionsComponent()
  is_selected = false;
  is_loading = false;
  platform_selected

  listDeveloperQTypes = this.f.getCategoriesDevelopersQuestions()
  self_assessment_tabs_names = [];

  selected_tab = 0
  selected = new FormControl(0);
  constructor(private secureStorage: EncryptedStorageService) {
  }

  ngOnInit() {

  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selected_tab = tabChangeEvent.index
  }

  selectPlatform() {
    this.selected = new FormControl(0);
    this.is_selected = true
    this.f.setSelectPlatform = this.platform_selected;
  }
}
