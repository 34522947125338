import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContextualService } from '../api/contextual.service';
import *  as constants from '../../utilities/constants';
import { EncryptedStorageService } from 'src/app/utilities/encryptedStorageService';

@Injectable({
  providedIn: 'root'
})
export class ContextualQDOAService {

  contextualQService: ContextualService
  constructor(private http: HttpClient,
    private secureStorage: EncryptedStorageService) {
    this.contextualQService = new ContextualService(this.http, null, null);
  }

  public getContextualQList() {

    return this.contextualQService.getContextualQuestions();
  }

  public getContextualQAnswers(platform_id) {
    return this.contextualQService.getContextualAnswers(platform_id)
  }

  public getContextualQuestions() {
    let contextualList = [];
    this.getContextualQList().subscribe(
      response => {
        contextualList = response["contextual_questions"];

        contextualList.sort(function (a, b) {
          return a.question_orderofappearance - b.question_orderofappearance;
        });

        contextualList.forEach(cQ => {
          let listAnswers = cQ.potential_answers;
          listAnswers.sort(function (a, b) {
            return a.answer_orderofappearance - b.answer_orderofappearance;
          });
          cQ.potential_answers = listAnswers;
          cQ.originalOrder = cQ.question_orderofappearance;

        });
        this.secureStorage.secureLocalStorage(constants.lSN_contextualQuestions, JSON.stringify(contextualList))
      });
  }
  insertNewContextualQuestion(body) {
    return this.contextualQService.insertNewContextualQuestions(body)
  }

  updateListContextualQuestion(body) {
    return this.contextualQService.updateContextualQuestions(body)
  }

  updateOrderContextualQuestions(body) {
    return this.contextualQService.updateOrderCQuestions(body);
  }
}
