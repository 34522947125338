import { Component, OnInit } from '@angular/core';
import *  as constants from '../../utilities/constants';
import { ContextualQuestion, ContextualQuestionClass, ContextualQuestionToSave, itemSaveOrderCQ, saveOrderArrayCQ } from 'src/app/models/contextual_question';
import { ContextualQDOAService } from 'src/app/api/DOA/contextual-q-doa.service';
import { ContextualPotentialAnswer } from 'src/app/models/contextual_potential_answer';
import { EncryptedStorageService } from 'src/app/utilities/encryptedStorageService';

@Component({
  selector: 'app-p-dm-recommendation-questions',
  templateUrl: './p-dm-recommendation-questions.component.html',
  styleUrls: ['./p-dm-recommendation-questions.component.css']
})
export class PDmRecommendationQuestionsComponent implements OnInit {
  initalList: ContextualQuestion[];
  showNewContextualQ = false;
  submitted = false;
  errorMsg = "";
  all_contextualQs: ContextualQuestion[] = JSON.parse(this.secureStorage.decryptLocalSecureStorage(constants.lSN_contextualQuestions));
  contextualQ_selected: ContextualQuestion;
  potential_answers: ContextualPotentialAnswer[] = [];

  constructor(private contextualQDoa: ContextualQDOAService,
    private secureStorage: EncryptedStorageService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.contextualQDoa.getContextualQList().subscribe(
      response => {
        let list1 = response['contextual_questions'];
        let list = [];

        list1.forEach(cQuest => {
          if (!cQuest.flagged) {
            list.push(cQuest)
          }
        })
        list.sort(function (a, b) {
          return a.question_orderofappearance - b.question_orderofappearance;
        });


        list.forEach(cQ => {
          let listAnswers = cQ.potential_answers;
          listAnswers.sort(function (a, b) {
            return a.answer_orderofappearance - b.answer_orderofappearance;
          });
          cQ.potential_answers = listAnswers;
          cQ.originalOrder = cQ.question_orderofappearance;
        })
        this.secureStorage.secureLocalStorage(constants.lSN_contextualQuestions, JSON.stringify(list))
        this.initalList = JSON.parse(this.secureStorage.decryptLocalSecureStorage(constants.lSN_contextualQuestions));
        this.all_contextualQs = list;
        let orderList = [];
        list.forEach(cQ => { orderList.push(cQ.question_orderofappearance) });

      });
  }

  edit(data: ContextualQuestion) {
    this.errorMsg = "";
    this.contextualQ_selected = data;
    this.potential_answers = data.potential_answers;
    this.showNewContextualQ = !this.showNewContextualQ;
  }

  save() {
    let body = this.createBody(this.contextualQ_selected);

    this.contextualQDoa.updateListContextualQuestion(body).subscribe(
      response => {
        this.changeView();
        //this.statisticsService.updateStatisticsListInfo;
      },
      error => {
        this.errorMsg = "Status: " + error.status + " error msg: " + error.error.msg;
        console.log("error", error)
        //this.statisticsService.updateStatisticsListInfo;
      });

  }

  cancel() {
    this.errorMsg = "";
    this.loadData();
    this.potential_answers = [];
    this.showNewContextualQ = false;
  }

  changeView() {
    this.errorMsg = "";
    this.contextualQ_selected = new ContextualQuestionClass();
    this.potential_answers = [];

    this.showNewContextualQ = !this.showNewContextualQ;
  }

  createBody(item: ContextualQuestion) {
    let body = {} as ContextualQuestionToSave;

    if (item.question_id != undefined) {
      body.question_id = item.question_id;
    }

    body.question_orderofappearance = item.question_orderofappearance;
    body.recommendation = item.recommendation;
    body.question_nrofanswers = item.nrofanswers;

    body.question_title = item.question_title;
    body.question_alternativetitle = item.question_alternativetitle;
    body.potential_answers = this.potential_answers;
    body.flagged = item.flagged;

    return body;
  }
}
